import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Nav, Navbar} from "react-bootstrap";
import { Link } from 'react-router-dom';
import NavRoutes from './Routes';
import "../../styles.css";

class MyAppNavbar extends Component {
  render() {
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="dark" fixed="top" variant="dark" >
          {/* <Navbar.Brand as={Link} to="https://kevinvanslyke.com" >
            <img src="https://facebook.github.io/react/img/logo.svg" weign="40" height="40"/>
          </Navbar.Brand> */}
          <Navbar.Brand>
            <a href="https://kevinvanslyke.com">kvs</a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav activeKey={window.location.pathname} variant="pills">
              <Nav.Item href="/">
                <Nav.Link as={Link} to="/" eventKey="/home" title="Home">
                    Home
              </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/launches" eventKey="Launches" title="Launches">
                    Launches
              </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/entries" eventKey="Entries" title="Entries">
                    Entries
              </Nav.Link>
              </Nav.Item>

              {/* <NavDropdown title="Products" id="nav-dropdown">
                <NavDropdown.Item>
                    Basic Pricing
              </NavDropdown.Item>

                <NavDropdown.Item>
                    Corporate
              </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item> Enterprise pricing
              </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <NavRoutes />
      </>
    );
  }
}
export default MyAppNavbar;