// import { BrowserRouter as Router, Link } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import MyAppNavbar from "./MyAppNavbar";
// import Routes from "./Routes"
// import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";

export default function TopNav() {
  return (
    <Router>
      <MyAppNavbar />
      {/* <div className="topNav" display="block">
        <div className="home">
          <Link to="/">Home</Link>
        </div>
        <div className="launches">
          <Link to="/launches">Spacex Launches</Link>
        </div>
        <div className="entries">
          <Link to="/entries">Fauna Entries</Link>
        </div>
      </div>

      <hr /> */}
      {/* <Routes /> */}
    </Router>
  )
}

