import { listGuestbookEntries } from '../../lib/fauna';

////////////////////////////////////////////////////////
// Selectors (take store-state, return data from store-state)
export const getEntries = (state) => {
  return state.entries;
};
////////////////////////////////////////////////////////
// Actions (take data, return an action object that will be injected into the store)
export const addEntryAction = (val) => ({
  type: "entries/added",
  payload: val
});
export const fetchedEntriesListAction = (val) => ({
  type: "entries/fetched",
  payload: val
});
////////////////////////////////////////////////////////
// Reducers (take store-state and an action object, return the modified store-state)
const initialState = [];
export const entriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "entries/added": {
      // const val = payload;
      return state;
    }
    case "entries/fetched": {
      return payload;
    }
    default: {
      return state;
    }
  }
};
////////////////////////////////////////////////////////
// Thunks (take a dispatch function and a getState function, and return
// a function that messes with dispatch() and getState() for profit)

export const fetchEntriesAsyncThunk = () => async (dispatch, getState) => {
  try {
    const response = await listGuestbookEntries();
    dispatch(fetchedEntriesListAction(response));
  } catch (error) {
    console.error("error fetching guestbook entries", error);
    // TODO Dispatch some kind of error
  }
};
