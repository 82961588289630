import { Container, Table } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import "../../styles.css";
import EntryTableRow from "./EntryTableRow";
import EntryTableHeader from "./EntryTableHeader";
import { getEntries } from "./entriesSlice";
// import { Card, Button } from "react-bootstrap";

export default function EntriesList() {
  // const dispatch = useDispatch();
  const launches = useSelector(getEntries, shallowEqual);
  return (
    <Container className='outerContainer'>
      {/* <Card style={{ width: '12rem' }}>
        <Card.Img variant="top" src="holder.js/100px180" />
        <Card.Body>
          <Card.Title>Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the bulk of
            the card's content.
          </Card.Text>
          <Button variant="primary">Go somewhere</Button>
        </Card.Body>
      </Card> */}
      <h1>Fauna Product Entries</h1>
      <Container>
      </Container>
      <Table>
        <EntryTableHeader />
        <tbody>
          {launches.map((entry, i) => (
            <EntryTableRow key={entry._id} entry={entry} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
