export default function LaunchTableRow({ launch }) {
  return (
    <tr>
      <td>{launch.mission_name}</td>
      <td>{launch.launch_date_local}</td>
      <td>
        <a href={launch.links.video_link}
          style={{ 'targetNew': "tab" }} target={"blank"}>{launch.links.video_link}
        </a>
      </td>
    </tr>
  );
}
