import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./styles.css";

export default function EntriesList() {
  return (
    <Container className='outerContainer'>
      <h1>This is a demo app using create-react-app.</h1>
      <div className='link'>
        <p>
          Here is a list of Spacex launches using their public Graphql API: <Link to="/launches">Spacex Launches</Link></p>
      </div>
      <div className='link'>
        <p>
          Here is a list of generic product entries using Fauna and Graphql: <Link to="/entries">Fauna Entries</Link></p>
      </div>
    </Container>
  );
}