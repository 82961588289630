import { Routes, Route } from 'react-router-dom';
import HomeLanding from "../../features/home/homeLanding";
import LaunchesList from "../../features/launches/LaunchesList";
import EntriesList from "../../features/entries/EntriesList";

export default function NavRoutes() {
  return (
    <Routes>
      <Route exact path='/' element={<HomeLanding />}/>
      <Route path='/launches' element={<LaunchesList />}/>
      <Route path='/entries' element={<EntriesList />}/>
    </Routes>
  )
}
