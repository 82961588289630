import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { fetchLaunchesAsyncThunk } from "./features/launches/launchesSlice";
import { fetchEntriesAsyncThunk } from "./features/entries/entriesSlice";
import { store } from "./store";
import TopNav from "./site/topNav/TopNav";


store.dispatch(fetchLaunchesAsyncThunk());
store.dispatch(fetchEntriesAsyncThunk());
const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <TopNav />
    </Provider>
  </StrictMode>,
  rootElement
);
