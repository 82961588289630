import { Container, Table } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import "./styles.css";
import LaunchTableControls from "./LaunchTableControls";
import LaunchTableRow from "./LaunchTableRow";
import LaunchTableHeader from "./LaunchTableHeader";
import { getLaunches } from "./launchesSlice";

export default function LaunchesList() {
  // const dispatch = useDispatch();
  const launches = useSelector(getLaunches, shallowEqual);
  return (
    <Container className='outerContainer'>
      <h1>Spacex Launches</h1>
      <LaunchTableControls />
      <Table>
        <LaunchTableHeader />
        <tbody>
          {launches.map((launch, i) => (
            <LaunchTableRow key={i} launch={launch} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
