import { Container, FormControl } from "react-bootstrap";
import "./styles.css";

export default function LaunchTableControls() {
  return (
    <Container>
      <div className="LaunchControlBox">
        <div className="LaunchControlBoxSearch">
          <FormControl
            placeholder="not implemented yet"
            aria-label="type to search"
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
    </Container>
  );
}
