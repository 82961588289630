export default function LaunchTableHeader() {
  return (
    <thead>
      <tr>
        <th>Mission Name</th>
        <th>Launch Date</th>
        <th>Video Link</th>
      </tr>
    </thead>
  );
}
