import { GraphQLClient, gql } from 'graphql-request'

const FAUNA_GRAPHQL_BASE_URL = 'https://graphql.us.fauna.com/graphql'

const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
  headers: {
    authorization: `Bearer ${process.env.REACT_APP_FUANA_CLIENT_SECRET}`,
  },
})

export const listGuestbookEntries = () => {
  const query = gql`
    query Entries($size: Int) {
      allProducts(_size: $size) {
        data {
          _id
          _ts
          name
          description
          price
        }
      }
    }
  `

  return graphQLClient
    .request(query, { size: 999 })
    .then(({ allProducts: { data } }) => data)
}

export const createGuestbookEntry = (newEntry) => {
  const mutation = gql`
    mutation CreateGuestbookEntry($input: GuestbookEntryInput!) {
      createGuestbookEntry(data: $input) {
        _id
        _ts
        name
        message
        createdAt
      }
    }
  `

  return graphQLClient.request(mutation, { input: newEntry })
}