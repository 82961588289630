import { applyMiddleware, createStore, combineReducers } from "redux";
import { launchesReducer } from "./features/launches/launchesSlice";
import { entriesReducer } from "./features/entries/entriesSlice";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension'

const composedEnhancer = composeWithDevTools(
  // Add whatever middleware you actually want to use here
  applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // other store enhancers if any
)

export const store = createStore(
  combineReducers(
    { launches: launchesReducer, entries: entriesReducer }),
  composedEnhancer)