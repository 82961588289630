////////////////////////////////////////////////////////
// Selectors (take store-state, return data from store-state)
export const getLaunches = (state) => {
  return state.launches;
};
////////////////////////////////////////////////////////
// Actions (take data, return an action object that will be injected into the store)
export const addLaunchAction = (val) => ({
  type: "launches/added",
  payload: val
});
export const fetchedLaunchesListAction = (val) => ({
  type: "launches/fetched",
  payload: val
});
////////////////////////////////////////////////////////
// Reducers (take store-state and an action object, return the modified store-state)
const initialState = [];
export const launchesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "launches/added": {
      // const val = payload;
      return state;
    }
    case "launches/fetched": {
      return payload;
    }
    default: {
      return state;
    }
  }
};
////////////////////////////////////////////////////////
// Thunks (take a dispatch function and a getState function, and return
// a function that messes with dispatch() and getState() for profit)

export const fetchLaunchesAsyncThunk = () => async (dispatch, getState) => {
  try {
    const response = await fetch('https://api.spacex.land/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `{
            launchesPast(limit: 10) {
                mission_name
                launch_date_local
                links {
                    video_link
                }
            }
        }`
      })
    });
    if (!response.ok) {
      // TODO Dispatch some kind of error
      throw Error(response.statusText);
    } else {
      const json = await response.json();
      dispatch(fetchedLaunchesListAction(json.data.launchesPast));
    }
  } catch (error) {
    console.error("error fetching launch records", error);
    // TODO Dispatch some kind of error
  }
};
